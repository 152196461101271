<template>
  <div class="base">
    <div
      class="base_head scaleBanner"
      :style="'background-image: url(' + provinceInfo.companyImage + ');'"
    >
      <div class="base_head_name moveTopTitle">
        {{ provinceInfo.companyName }}
      </div>
      <div class="base_head_desc moveTopTitle">
        {{ provinceInfo.companyDesc }}
      </div>
      <div class="base_head_img">
        <img src="../../static/newImg/base_news_bg_fff.png" width="100%" />
      </div>
    </div>
    <div class="base_newsImg">
      <div class="base_newsImg_top_title_cn">新闻资讯</div>
      <div class="base_newsImg_top_title_en">NEWS INFORMATION</div>
      <div class="base_news moveTopNormal">
        <div class="base_news_bg">
          <el-carousel
            height="320px"
            indicator-position="none"
            ref="newsImgCarousel"
            arrow="never"
            @change="changeNewsImgCarousel"
            class="newsImgCarousel"
          >
            <el-carousel-item
              v-for="(item, index) in newsList"
              :key="item.newsId + 'newsImg'"
            >
              <img
                :src="item.newsCover + imgSuffix"
                width="586px"
                @click="toDetail(item, 22)"
              />
            </el-carousel-item>
          </el-carousel>
          <div class="base_news_bg_bottom">
            <div
              class="base_news_bg_bottom_title"
              v-if="newsList.length"
              @click="toDetail(newsList[newsImageIndex], 22)"
            >
              {{ newsList[newsImageIndex].newsTitle }}
            </div>
            <div>
              <div
                :class="
                  newsImageIndex == index
                    ? 'base_news_bg_bottom_Index active'
                    : 'base_news_bg_bottom_Index'
                "
                v-for="(i, index) in newsList"
                @click="changeNewsImageIndex(index)"
                :key="index + 'newsImg1'"
              >
                {{ index + 1 }}
              </div>
            </div>
          </div>
        </div>
        <div class="base_news_left">
          <div class="base_news_left_title">
            <div
              :class="
                newsIndex == 33
                  ? 'base_news_left_title_name active'
                  : 'base_news_left_title_name'
              "
              @mouseenter="
                changeNewsIndex33(33);
                newsIndex = 33;
              "
            >
              最新报道
            </div>
            <div
              :class="
                newsIndex == 34
                  ? 'base_news_left_title_name active'
                  : 'base_news_left_title_name'
              "
              @mouseenter="
                changeNewsIndex33(34);
                newsIndex = 34;
              "
            >
              新闻资讯
            </div>
          </div>
          <div
            class="base_news_left_list moveTop"
            @click="toDetail(item, 22)"
            v-for="item in newsList1"
            :key="'newsList' + item.newsId"
          >
            <div class="base_news_left_list_title">
              <div class="base_news_left_list_title_point"></div>
              {{ item.newsTitle }}
            </div>
            <div class="base_news_left_list_day">{{ item.publishTime }}</div>
          </div>
        </div>
      </div>
      <!-- 非基地公司没有行业资讯和热点专题 -->
      <!-- <div class="base_news1">
        <div class="base_news1_bg">
          <img class="base_news1_bg_img" src="../../static/newImg/base_news1_title.png" height="14px" />
          <div class="base_news1_bg_title">行业资讯</div>
          <div class="base_news1_bg_list moveTop" v-for="item in newsList3" :key="item.id" @click="toDetail(item, 22)">
            <div class="base_news1_bg_list_point"></div>
            {{ item.newsTitle }}
          </div>
        </div>
        <div class="base_news1_bg">
          <img class="base_news1_bg_img" src="../../static/newImg/base_news1_title.png" height="14px" />
          <div class="base_news1_bg_title">热点专题</div>
          <div class="base_news1_bg_list moveTop" v-for="item in newsList4" :key="item.id" @click="toDetail(item, 22)">
            <div class="base_news1_bg_list_point"></div>
            {{ item.newsTitle }}
          </div>
        </div>
      </div> -->
      <div class="base_banner moveTopNormal">
        <img
          src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/e4e8ea1d446c4ba1bcb5e76dbbd163ed.png"
          width="1200px"
        />
      </div>
    </div>
    <div class="base_moreList moveTopNormal">
      <div class="base_moreList_top_title_cn">更多资讯</div>
      <div class="base_moreList_top_title_en">MORE INFORMATION</div>
      <div class="base_moreList_list">
        <div
          class="base_moreList_list_item"
          v-for="item in newsList5"
          :key="item.newsId"
          @click="toDetail(item)"
        >
          <div class="base_moreList_list_item_img">
            <img :src="item.newsCover + imgSuffix" width="100%" />
          </div>
          <div class="base_moreList_list_item_content">
            <div class="base_moreList_list_item_content_title">
              {{
                item.newsTitle.length > 25
                  ? item.newsTitle.substring(0, 25) + "..."
                  : item.newsTitle
              }}
            </div>
            <div
              class="base_moreList_list_item_content_tips"
              v-if="item.categoryNames"
            >
              {{
                item.categoryNames.substring(
                  item.categoryNames.length - 4,
                  item.categoryNames.length
                )
              }}&nbsp;&nbsp;|&nbsp;&nbsp;{{ item.publishTime }}
            </div>
            <div
              class="base_moreList_list_item_content_tips"
              v-if="!item.categoryNames"
            >
              行业资讯&nbsp;&nbsp;|&nbsp;&nbsp;{{ item.publishTime }}
            </div>
          </div>
        </div>
      </div>
      <div class="base_moreList_page">
        <el-pagination
          layout="prev, pager, next"
          :page-size="query.size"
          :current-page="query.current"
          :total="query.total"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 子公司 -->
    <div
      class="base_child_top"
      v-show="provinceInfo.city && provinceInfo.city.length > 0"
    >
      <div class="base_child_top_title_cn">市级公司</div>
      <div class="base_child_top_title_en">COMPANY LIST</div>
      <div class="base_child_top_img">
        <el-carousel
          type="card"
          height="500px"
          indicator-position="none"
          ref="cityCarousel"
          @change="changeCityIndex"
          class="cityCarousel"
          :autoplay="false"
        >
          <el-carousel-item
            v-for="item in provinceInfo.city"
            :key="item.companyId"
          >
            <div
              class="base_child_top_img_out"
              @click="toCompanyDetail(item.companyId)"
            >
              <div class="base_child_top_img_out_img">
                <div>{{ item.companyName }}</div>
                <img
                  style="display: block"
                  :src="item.companyImage + imgSuffix"
                  width="100%"
                />
              </div>
              <div class="base_child_top_img_out_tips">
                <div class="base_child_top_img_out_tips_title">
                  {{ item.companyName }}
                </div>
                <div class="base_child_top_img_out_tips_desc">
                  {{ item.companyDesc }}
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="base_child_top_tips">
        <img
          class="base_child_top_tips_img"
          src="../../static/newImg/base_news_child_1.png"
          width="24px"
        />
        <div class="base_child_top_tips_line"></div>
        <div class="swiper-container1">
          <div class="swiper-wrapper">
            <div
              class="base_child_top_tips_name swiper-slide swiper-no-swiping"
              v-for="item in provinceInfo.city"
              :key="item.companyId"
              @click="changeCompany(item.companyId)"
            >
              {{ item.companyName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="base_child_tips"
      v-show="provinceInfo.city && provinceInfo.city.length > 0"
    >
      <img src="../../static/newImg/base_news_child_2.png" width="25px" />
    </div>
    <div
      class="base_child_info"
      v-show="provinceInfo.city && provinceInfo.city.length > 0"
    >
      <div class="base_child_info_title">
        <div class="base_child_info_title_cn">新闻列表</div>
        <div class="base_child_info_title_en">NEW LIST</div>
      </div>
      <div class="base_child_info_news">
        <div class="base_child_info_news_bg">
          <el-carousel
            height="320px"
            indicator-position="none"
            ref="newsChildImgCarousel"
            arrow="never"
            @change="changeChildNewsImgCarousel"
            class="newsImgCarousel"
          >
            <el-carousel-item
              v-for="(item, index) in childNewsList"
              :key="item.newsId + 'newsImg'"
            >
              <img
                :src="item.newsCover + imgSuffix"
                width="586px"
                @click="toDetail(item, 22, 'child')"
              />
            </el-carousel-item>
          </el-carousel>
          <div class="base_child_info_news_bg_bottom">
            <div
              class="base_child_info_news_bg_bottom_title"
              v-if="childNewsList.length"
              @click="toDetail(childNewsList[childNewsImageIndex], 22, 'child')"
            >
              {{ childNewsList[childNewsImageIndex].newsTitle }}
            </div>
            <div>
              <div
                :class="
                  childNewsImageIndex == index
                    ? 'base_child_info_news_bg_bottom_Index active'
                    : 'base_child_info_news_bg_bottom_Index'
                "
                v-for="(i, index) in childNewsList"
                @click="changeChildNewsImageIndex(index)"
                :key="index + 'newsImg1'"
              >
                {{ index + 1 }}
              </div>
            </div>
          </div>
        </div>
        <div class="base_child_info_news_left">
          <div class="base_child_info_news_left_title">
            <div
              :class="
                childNewsIndex == 33
                  ? 'base_child_info_news_left_title_name active'
                  : 'base_child_info_news_left_title_name'
              "
              @mouseenter="
                getChildList1(33);
                childNewsIndex = 33;
              "
            >
              最新报道
            </div>
            <div
              :class="
                childNewsIndex == 34
                  ? 'base_child_info_news_left_title_name active'
                  : 'base_child_info_news_left_title_name'
              "
              @mouseenter="
                getChildList1(34);
                childNewsIndex = 34;
              "
            >
              新闻资讯
            </div>
          </div>
          <div
            class="base_child_info_news_left_list moveTop"
            @click="toDetail(item, 22, 'child')"
            v-for="item in childNewsList1"
            :key="'newsList' + item.newsId"
          >
            <div class="base_child_info_news_left_list_title">
              <div class="base_child_info_news_left_list_title_point"></div>
              {{ item.newsTitle }}
            </div>
            <div class="base_child_info_news_left_list_day">
              {{ item.publishTime }}
            </div>
          </div>
        </div>
      </div>
      <div class="base_child_info_title" v-show="childProjectList.length">
        <div class="base_child_info_title_cn">项目列表</div>
        <div class="base_child_info_title_en">PROJECT LIST</div>
      </div>
      <div class="base_child_info_project" v-show="childProjectList.length > 1">
        <div
          class="base_child_info_project_item"
          v-for="item in childProjectList"
          :key="item.projectId"
          @click="toProject(item)"
        >
          <!-- {{ item.projectName }} -->
          <div class="base_child_info_project_item_img">
            <img :src="item.projectImage + imgSuffix" width="590px" />
          </div>
          <div class="base_child_info_project_item_tips">
            <div class="base_child_info_project_item_tips_left">
              项目名称：{{ item.projectName }}
            </div>
            <div class="base_child_info_project_item_tips_left">
              项目时间：{{ item.projectDate | fomatterDate }}
            </div>
            <div class="base_child_info_project_item_tips_left">
              产品介绍：<br />
              <div class="base_child_info_project_item_tips_right">
                {{ item.projectDesc }}
              </div>
            </div>
            <div class="base_child_info_project_item_tips_more">
              了解详情<img
                src="../../static/newImg/位图9.png"
                width="16px"
                height="16px"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="base_child_info_project"
        v-show="childProjectList.length == 1"
      >
        <div
          class="base_child_info_project_item_only"
          v-for="item in childProjectList"
          :key="item.projectId"
          @click="toProject(item)"
        >
          <!-- {{ item.projectName }} -->
          <div class="base_child_info_project_item_img">
            <img :src="item.projectImage + imgSuffix" width="590px" />
          </div>
          <div class="base_child_info_project_item_tips">
            <div class="base_child_info_project_item_tips_left">
              项目名称：{{ item.projectName }}
            </div>
            <div class="base_child_info_project_item_tips_left">
              项目时间：{{ item.projectDate | fomatterDate }}
            </div>
            <div class="base_child_info_project_item_tips_left">
              产品介绍：<br />
              <div class="base_child_info_project_item_tips_right">
                {{ item.projectDesc }}
              </div>
            </div>
            <div class="base_child_info_project_item_tips_more">
              了解详情<img
                src="../../static/newImg/位图9.png"
                width="16px"
                height="16px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Pagination, Autoplay } from "swiper";
import {
  getCompanyList,
  getCompanyDetail,
  getPojectDetail,
} from "@/api/index.js";
import axios from "axios";
export default {
  name: "Base",
  data() {
    return {
      imgSuffixTime: null,
      imgSuffix: "?x-oss-process=image/resize,m_lfit,w_300",
      provinceInfo: {
        city: [],
      },
      cityIndex: 0,
      newsList: [],
      newsList1: [],
      newsList2: [],
      newsList3: [],
      newsList4: [],
      newsList5: [],
      childNewsList: [],
      childNewsList1: [],
      newsImageIndex: 0,
      childNewsImageIndex: 0,
      newsIndex: 33,
      childNewsIndex: 33,
      childProjectList: [],
      container1: null,
      container2: null,
      query: {
        current: 1,
        size: 6,
        total: 0,
      },
    };
  },
  filters: {
    fomatterDate: (val) => {
      if (!val) {
        return "";
      }
      if (val.length > 11) {
        val = val.replace(",", "日 至 ");
        val = val.replace("-", "年");
        val = val.replace("-", "月");
        val = val.replace("-", "年");
        val = val.replace("-", "月");
        val += "日";
      } else {
        val = val.replace("-", "年");
        val = val.replace("-", "月");
        val = val.replace("-", "日");
      }
      return val;
    },
  },
  mounted() {
    this.imgSuffixTime = setTimeout(() => {
      this.imgSuffix = "";
    }, 4000);
    Swiper.use([Pagination, Autoplay]);
    window.scroll(0, 0);
    if (this.$route.query.companyId) {
      this.companyId = this.$route.query.companyId;
    }
    if (this.$refs.newsImgCarousel) {
      this.slideBanner();
    }
    this.getCompanyDetail();
    this.changeNewsIndex(33);
    this.changeNewsIndex33(33);
    this.changeNewsIndex34(34);
    // this.changeNewsIndex35(35);
    // this.changeNewsIndex36(36);
    this.changeNewsIndex3536();
    if (this.$refs.cityCarousel) {
      this.slideBannerCityCarousel();
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.query.current = val;
      this.changeNewsIndex3536();
    },
    slideBannerCityCarousel() {
      //选中的轮播图
      var box = document.querySelector(".cityCarousel .el-carousel__container");
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      };
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this;
      box.addEventListener("touchend", function (e) {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.cityCarousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.cityCarousel.prev();
          return;
        }
      });
    },
    showDetail() {
      this.$parent.nav_click(2);
    },
    changeCityIndex(index, index1) {
      if (index == 0) {
        this.cityIndex = 0;
        this.container1.slideTo(0);
      } else {
        if (index > index1) {
          this.cityIndex += 1;
          this.container1.slideNext();
        } else {
          this.cityIndex -= 1;
          this.container1.slidePrev();
        }
      }
      this.childNewsIndex = 33;
      this.childNewsImageIndex = 0;
      if (this.provinceInfo.city.length != 0) {
        this.getChildList(33);
        this.getChildList1(33);
        this.getProjectList();
      }
    },
    getCompanyDetail() {
      getCompanyDetail(this.companyId).then((res) => {
        this.provinceInfo = res.data;
        this.getCompanyList();
      });
    },
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector(
        ".newsImgCarousel .el-carousel__container"
      );
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      };
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this;
      box.addEventListener("touchend", function (e) {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.newsImgCarousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.newsImgCarousel.prev();
          return;
        }
      });
    },
    changeNewsImageIndex(index) {
      this.newsImageIndex = index;
      this.$refs.newsImgCarousel.setActiveItem(index);
    },
    changeChildNewsImageIndex(index) {
      this.childNewsImageIndex = index;
      this.$refs.newsChildImgCarousel.setActiveItem(index);
    },
    changeNewsImgCarousel(index) {
      this.newsImageIndex = index;
    },
    changeChildNewsImgCarousel(index) {
      this.childNewsImageIndex = index;
    },
    toDetail(item, index, type) {
      if (type == "child") {
        const id3 = this.provinceInfo.city[this.cityIndex].companyId;
        const name3 = this.provinceInfo.city[this.cityIndex].companyName;
        this.$router.push({
          path: "/companyNewsListDetail",
          query: {
            id: item.newsId,
            name1: "首页",
            name2: item.companyName,
            id2: item.companyId,
            name3,
            id3,
          },
        });
      } else {
        this.$router.push({
          path: "/companyNewsListDetail",
          query: {
            id: item.newsId,
            name1: "首页",
            name2: this.provinceInfo.companyName,
            id2: this.provinceInfo.companyId,
          },
        });
      }
    },
    toCompanyDetail(companyId) {
      this.$router.push({
        path: "/baseCompanyDetail",
        query: {
          companyId: companyId,
          pCompanyName: this.provinceInfo.companyName,
          pCompanyId: this.provinceInfo.companyId,
        },
      });
    },
    changeCompany(companyId) {
      if (this.provinceInfo.city[this.cityIndex].companyId == companyId) {
        this.toCompanyDetail(companyId);
      }
      this.provinceInfo.city.forEach((val, index) => {
        if (val.companyId == companyId) {
          this.$refs.cityCarousel.setActiveItem(index);
          this.container1.slideTo(index);
        }
      });
    },
    toProject(item) {
      getPojectDetail(item.projectId).then((res) => {
        const resource = res.data.base.resource.length;
        const productDTOList = res.data.productDTOList.length;
        const landDTOList = res.data.landDTOList.length;
        if (resource && productDTOList && landDTOList) {
          this.$router.push({
            path: "/projectDetail",
            query: { id: item.projectId },
          });
        }
      });
    },
    changeNewsIndex(index) {
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=4&websiteCategoryId=${index}&companyId=${this.companyId}`
        )
        .then(async (res) => {
          this.newsList = res.data.data.list;
        });
    },
    changeNewsIndex33(index) {
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=5&websiteCategoryId=${index}&companyId=${this.companyId}`
        )
        .then(async (res) => {
          this.newsList1 = res.data.data.list;
        });
    },
    changeNewsIndex34(index) {
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=7&websiteCategoryId=${index}&companyId=${this.companyId}`
        )
        .then(async (res) => {
          this.newsList2 = res.data.data.list;
        });
    },
    changeNewsIndex3536() {
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=${this.query.current}&size=${this.query.size}&websiteCategoryIds=35,36&companyId=${this.companyId}`
        )
        .then(async (res) => {
          this.newsList5 = res.data.data.list;
          this.query.total = res.data.data.total;
        });
    },
    getCompanyList() {
      getCompanyList({ current: 1, size: 4, pid: this.companyId }).then(
        (res) => {
          this.provinceInfo.city = res.data.list;
          if (this.provinceInfo.city.length != 0) {
            this.getChildList(33);
            this.getChildList1(33);
            this.getProjectList();
            this.$nextTick(() => {
              this.container1 = new Swiper(".swiper-container1", {
                loop: true,
                slidesPerView: 4,
                spaceBetween: 30,
                observer: true, //开启动态检查器，监测swiper和slide
                observeParents: true, //监测Swiper 的祖/父元素
                centeredSlides: true,
              });
              setTimeout(() => {
                this.$refs.cityCarousel.setActiveItem(1);
              }, 500);
            });
          }
        }
      );
    },
    getChildList1(websiteCategoryId) {
      const index = this.provinceInfo.city[this.cityIndex].companyId;
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=4&companyId=${index}&websiteCategoryId=${websiteCategoryId}`
        )
        .then(async (res) => {
          this.childNewsList1 = res.data.data.list;
        });
    },
    getChildList(websiteCategoryId) {
      const index = this.provinceInfo.city[this.cityIndex].companyId;
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=4&companyId=${index}&websiteCategoryId=${websiteCategoryId}`
        )
        .then(async (res) => {
          this.childNewsList = res.data.data.list;
        });
    },
    getProjectList() {
      const index = this.provinceInfo.city[this.cityIndex].companyId;
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/project/list?current=1&size=1000&companyId=${index}`
        )
        .then(async (res) => {
          this.childProjectList = res.data.data.list;
        });
    },
    toHome() {
      this.$router.push({
        path: "/",
      });
    },
  },
  watch: {
    $route() {
      if (this.$route.query.companyId) {
        this.companyId = this.$route.query.companyId;
        this.getCompanyList();
        this.getCompanyDetail();
        this.changeNewsIndex(33);
        this.changeNewsIndex33(33);
        this.changeNewsIndex34(34);
        this.changeNewsIndex35(35);
        this.changeNewsIndex36(36);
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.imgSuffixTime);
  },
};
</script>

<style scoped lang="scss">
.base {
  width: 100%;
  background-color: #fff;

  .base_head {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    height: 728px;
    padding-top: 30px;

    .base_bg {
      width: 100%;
    }

    .base_head_select_out {
      width: 1200px;
      margin: 0 auto;

      .base_head_select {
        position: absolute;
        padding: 0 30px;
        height: 70px;
        border-radius: 35px;
        line-height: 70px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        letter-spacing: 2px;
        cursor: pointer;
        margin-top: 100px;

        .base_head_select_img {
          vertical-align: middle;
        }
      }
    }

    .base_head_name {
      width: 100%;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translate(-50%, -50%);
      font-size: 70px;
      font-weight: bold;
      color: #ffffff;
      letter-spacing: 8px;
      font-family: 楷体;
    }

    .base_head_desc {
      width: 1000px;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-top: 90px;
      font-weight: 500;
      font-size: 24px;
      color: #ffffff;
      line-height: 48px;
      letter-spacing: 1px;
      text-align: center;
    }
    .base_head_img {
      position: absolute;
      bottom: -10px;
      left: 0;
    }
  }

  .base_head::before {
    content: " ";
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(18, 50, 17, 0.88) 100%
    );
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .base_newsImg {
    background: url("https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/4a8e29b84efb4e0cb339d6979f13fe59.jpg")
      no-repeat;
    background-position: 0% 70%;
    background-size: cover;
    height: 880px;
    padding: 50px 120px;

    .base_newsImg_top_title_cn {
      font-size: 31px;
      font-weight: bold;
      color: #000000;
      line-height: 47px;
      text-align: center;
    }

    .base_newsImg_top_title_en {
      margin-top: 8px;
      font-size: 18px;
      font-weight: 400;
      color: #979797;
      line-height: 27px;
      letter-spacing: 2px;
      text-align: center;
    }

    .base_news {
      display: flex;
      width: 1200px;
      margin: 80px auto 120px auto;

      .base_news_bg {
        width: 586px;
        position: relative;
        overflow: hidden;
        transform-style: preserve-3d;

        img {
          transform: scale(1.6) translateZ(-1px);
        }

        .base_news_bg_bottom {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          color: #fff;
          padding: 0 16px;
          font-size: 14px;
          font-weight: bold;
          width: calc(100% - 32px);
          height: 40px;
          line-height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #0000008a;

          .base_news_bg_bottom_title {
            cursor: pointer;
            width: 70%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .base_news_bg_bottom_Index {
            float: left;
            color: #354a32;
            width: 20px;
            height: 18px;
            position: relative;
            line-height: 18px;
            border: 1px solid #ffffff;
            text-align: center;
            margin-right: 5px;
            cursor: pointer;
            background: #ffffff9a;
          }

          .base_news_bg_bottom_Index:hover {
            color: #fff;
            background: #354a32;
            border: 1px solid #354a32;
          }

          .active {
            color: #fff;
            background: #354a32;
            border: 1px solid #ffffff;
          }
        }
      }

      .base_news_left {
        margin-left: 40px;
        width: calc(100% - 626px);

        .base_news_left_title {
          display: flex;
          width: 100%;
          border-bottom: 2px solid #efefef;
          margin-bottom: 20px;

          .base_news_left_title_name {
            margin-left: 10px;
            padding: 0 34px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            border: 1px solid #ddd;
            border-bottom: 0;
            background: #fff;
            color: #000;
            cursor: pointer;
            font-size: 23px;
          }

          .base_news_left_title_name:first-child {
            margin-left: 0;
          }

          .active {
            background: #354a32;
            color: #fff;
            transition: all 0.3s ease-in-out 0s;
          }
        }

        .base_news_left_list {
          cursor: pointer;
          width: 100%;
          display: flex;
          line-height: 46px;
          height: 46px;
          justify-content: space-between;

          .base_news_left_list_title {
            width: 85%;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            /* 当文本溢出时显示省略号 */
            .base_news_left_list_title_point {
              width: 4px;
              height: 4px;
              background: #8c8f92;
              opacity: 0.31;
              display: inline-block;
              margin-right: 8px;
              vertical-align: middle;
            }
          }

          .base_news_left_list_day {
            font-size: 13px;
            color: #0000008c;
          }
        }
      }
    }

    .base_news1 {
      display: flex;
      width: 1200px;
      margin: 50px auto;
      justify-content: space-between;

      .base_news1_bg {
        width: 580px;
        height: 320px;
        background: linear-gradient(180deg, #ebefe8 0%, #fcfcfc 100%);
        position: relative;
        text-align: center;

        .base_news1_bg_img {
          position: absolute;
          top: 42px;
          left: 0;
        }

        .base_news1_bg_title {
          margin-top: 28px;
          font-size: 26px;
          font-weight: 600;
          color: #354a32;
          line-height: 37px;
          margin-bottom: 25px;
        }

        .base_news1_bg_list {
          padding: 0 40px;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          font-weight: 400;
          color: #211f1f;
          line-height: 40px;
          text-align: left;

          .base_news1_bg_list_point {
            width: 4px;
            height: 4px;
            background: #8c8f92;
            opacity: 0.31;
            display: inline-block;
            margin-right: 8px;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .base_moreList {
    background: url("https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/4d22c9498b6440348114c3e80b5a571f.jpg")
      no-repeat;
    background-size: cover;
    padding: 80px 120px;

    .base_moreList_top_title_cn {
      width: 1200px;
      margin: 0 auto;
      font-size: 31px;
      font-weight: bold;
      color: #000000;
      line-height: 47px;
      text-align: center;
    }

    .base_moreList_top_title_en {
      width: 1200px;
      margin: 0 auto;
      margin-top: 8px;
      font-size: 18px;
      font-weight: 400;
      color: #979797;
      line-height: 27px;
      letter-spacing: 2px;
      text-align: center;
      margin-bottom: 80px;
    }

    .base_moreList_list {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .base_moreList_list_item {
        cursor: pointer;
        margin-bottom: 30px;
        width: 584px;

        .base_moreList_list_item_img {
          width: 584px;
          height: 340px;
          overflow: hidden;

          img {
            transform: scale(1.8);
          }
        }

        .base_moreList_list_item_content {
          background-color: #fff;
          padding: 25px;
          box-shadow: 0px 2px 32px 0px rgba(53, 74, 50, 0.18);

          .base_moreList_list_item_content_title {
            font-weight: bold;
            font-size: 18px;
            color: #211f1f;
            line-height: 35px;
            text-shadow: 0px 2px 32px rgba(53, 74, 50, 0.18);
          }

          .base_moreList_list_item_content_tips {
            margin: 40px 0 50px 0;
            font-weight: 500;
            font-size: 14px;
            color: #354a32;
            line-height: 21px;
            text-shadow: 0px 2px 32px rgba(53, 74, 50, 0.18);
          }
        }
      }
    }

    .base_moreList_page {
      margin: 20px auto;
      text-align: center;
      width: 1200px;
    }

    ::v-deep .el-pager li.active {
      width: 28px;
      height: 28px;
      background: #354a32;
      border-radius: 2px;
      color: #ffffff;
      line-height: 28px;
    }

    ::v-deep .el-pager li {
      width: 28px;
      height: 28px;
      min-width: 28px;
      border-radius: 2px;
      border: 1px solid #e5e5e5;
      color: #565759;
      line-height: 28px;
      padding: 0;
      margin: 0 4px;
      font-weight: 400;
    }

    ::v-deep .el-pager li.active + li {
      border: 1px solid #e5e5e5;
    }
  }

  .base_banner {
    width: 1200px;
    margin: 0 auto 40px auto;
  }

  .base_child_top {
    background: url("https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/7a71a092964e4be9b1c3a50510fa440d.png")
      no-repeat;
    background-size: cover;
    height: 974px;
    text-align: center;
    margin-top: 90px;
    position: relative;

    .base_child_top_title_cn {
      font-size: 31px;
      font-weight: bold;
      color: #ffffff;
      line-height: 47px;
      padding-top: 90px;
    }

    .base_child_top_title_en {
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      line-height: 27px;
      letter-spacing: 2px;
      margin-top: 22px;
    }

    .base_child_top_tips {
      width: 100%;
      height: 167px;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      bottom: 0;
      left: 0;

      .base_child_top_tips_img {
        margin-top: 18px;
        position: relative;
      }

      .base_child_top_tips_line::before {
        content: "";
        background-color: #fff;
        height: 5px;
        width: 120px;
        position: absolute;
        top: 56px;
        left: calc(50% - 60px);
      }

      .base_child_top_tips_line {
        width: 100%;
        height: 1px;
        background: rgba(255, 255, 255, 0.5);
        margin-top: 14px;
      }

      .base_child_top_tips_name {
        font-size: 24px;
        color: #ffffff;
        line-height: 108px;
        letter-spacing: 2px;
      }
    }

    .base_child_top_img {
      width: 1200px;
      margin: 40px auto;

      .base_child_top_img_out {
        box-shadow: 0px 2px 40px 0px #354a32;

        .base_child_top_img_out_img {
          position: relative;
          text-align: center;
          margin: 0 auto;

          div {
            width: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 34px;
            font-weight: bold;
            color: #ffffff;
            letter-spacing: 4px;
            text-shadow: 0px 2px 40px #354a32;
            z-index: 9;
          }
        }

        .base_child_top_img_out_img::after {
          content: " ";
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.38),
            rgba(0, 0, 0, 0.88)
          );
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }

        .base_child_top_img_out_tips {
          padding: 25px;
          background-color: #fff;
          width: 570px;
          height: 190px;
          margin: 0 auto;
          text-align: left;

          .base_child_top_img_out_tips_title {
            font-size: 20px;
            font-weight: 900;
            color: #354a32;
            line-height: 31px;
          }

          .base_child_top_img_out_tips_desc {
            margin: 15px 0;
            font-size: 16px;
            font-weight: 400;
            color: #6b766a;
            line-height: 27px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .base_child_tips {
    width: 100%;
    text-align: center;
    margin-bottom: 88px;
  }

  .base_child_info {
    width: 1200px;
    margin: 60px auto;

    .base_child_info_title {
      text-align: center;
      margin-top: 90px;

      .base_child_info_title_cn {
        font-size: 31px;
        font-weight: bold;
        color: #000000;
        line-height: 47px;
      }

      .base_child_info_title_en {
        margin-top: 8px;
        font-size: 18px;
        font-weight: 400;
        color: #979797;
        line-height: 27px;
        letter-spacing: 2px;
      }
    }

    .base_child_info_news {
      display: flex;
      width: 1200px;
      margin: 50px auto;

      .base_child_info_news_bg {
        width: 586px;
        position: relative;

        img {
          transform: scale(1.6);
        }

        .base_child_info_news_bg_bottom {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          color: #fff;
          padding: 0 16px;
          font-size: 14px;
          font-weight: bold;
          width: calc(100% - 32px);
          height: 40px;
          line-height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #0000008a;

          .base_child_info_news_bg_bottom_title {
            cursor: pointer;
            width: 70%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .base_child_info_news_bg_bottom_Index {
            float: left;
            color: #354a32;
            width: 20px;
            height: 18px;
            position: relative;
            line-height: 18px;
            border: 1px solid #ffffff;
            text-align: center;
            margin-right: 5px;
            cursor: pointer;
            background: #ffffff9a;
          }

          .base_child_info_news_bg_bottom_Index:hover {
            color: #fff;
            background: #354a32;
            border: 1px solid #354a32;
          }

          .active {
            color: #fff;
            background: #354a32;
            border: 1px solid #ffffff;
          }
        }
      }

      .base_child_info_news_left {
        margin-left: 40px;
        width: calc(100% - 626px);

        .base_child_info_news_left_title {
          display: flex;
          width: 100%;
          border-bottom: 2px solid #efefef;
          margin-bottom: 20px;

          .base_child_info_news_left_title_name {
            margin-left: 10px;
            padding: 0 34px;
            height: 69px;
            line-height: 69px;
            text-align: center;
            border: 1px solid #ddd;
            border-bottom: 0;
            background: #fff;
            color: #000;
            cursor: pointer;
            font-size: 23px;
          }

          .base_child_info_news_left_title_name:first-child {
            margin-left: 0;
          }

          .active {
            background: #354a32;
            color: #fff;
            transition: all 0.3s ease-in-out 0s;
          }
        }

        .base_child_info_news_left_list {
          cursor: pointer;
          width: 100%;
          display: flex;
          line-height: 46px;
          height: 46px;
          justify-content: space-between;

          .base_child_info_news_left_list_title {
            width: 85%;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            /* 当文本溢出时显示省略号 */
            .base_child_info_news_left_list_title_point {
              width: 4px;
              height: 4px;
              background: #8c8f92;
              opacity: 0.31;
              display: inline-block;
              margin-right: 8px;
              vertical-align: middle;
            }
          }

          .base_child_info_news_left_list_day {
            font-size: 13px;
            color: #0000008c;
          }
        }
      }
    }

    .base_child_info_project {
      margin: 10px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .base_child_info_project_item {
        cursor: pointer;
        width: 590px;
        margin-top: 20px;

        .base_child_info_project_item_img {
          width: 590px;
          overflow: hidden;

          img {
            display: block;
          }
        }

        .base_child_info_project_item_tips {
          padding: 24px;
          background-color: #fff;
          box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);

          .base_child_info_project_item_tips_left {
            font-size: 20px;
            font-weight: 900;
            color: #354a32;
            line-height: 40px;
          }

          .base_child_info_project_item_tips_right {
            margin-top: 12px;
            font-size: 16px;
            font-weight: 400;
            color: #6b766a;
            line-height: 27px;
            height: 85px;
            overflow: hidden;
          }

          .base_child_info_project_item_tips_more {
            width: 500px;
            margin-top: 30px;
            font-size: 12px;
            font-weight: 600;
            color: #595959;
            line-height: 20px;
            display: flex;
            align-items: center;

            img {
              vertical-align: middle;
              margin-left: 10px;
              transition: all 0.5s ease-out;
            }
          }
        }
      }

      .base_child_info_project_item_only {
        display: flex;
        margin-top: 20px;
        cursor: pointer;

        .base_child_info_project_item_img {
          width: 590px;
          overflow: hidden;

          img {
            display: block;
            transform: scale(1.2);
          }
        }

        .base_child_info_project_item_tips {
          width: calc(100% - 590px - 48px);
          padding: 24px;
          background-color: #fff;
          box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);

          .base_child_info_project_item_tips_left {
            font-size: 20px;
            font-weight: 900;
            color: #354a32;
            line-height: 40px;
          }

          .base_child_info_project_item_tips_right {
            margin-top: 12px;
            font-size: 16px;
            font-weight: 400;
            color: #6b766a;
            line-height: 27px;
            height: 85px;
            overflow: hidden;
          }

          .base_child_info_project_item_tips_more {
            width: 500px;
            margin-top: 30px;
            font-size: 12px;
            font-weight: 600;
            color: #595959;
            line-height: 20px;
            display: flex;
            align-items: center;

            img {
              vertical-align: middle;
              margin-left: 10px;
              transition: all 0.5s ease-out;
            }
          }
        }
      }
    }
  }
}

.swiper-container1,
.swiper-container2 {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .swiper-slide {
    overflow: hidden;
    cursor: pointer;
  }

  .swiper-slide-active {
    font-weight: bold;
  }
}

.swiper-container2 {
  .swiper-slide-active {
    width: 708px !important;
    z-index: 9;
    transform: scale(1.25);
  }

  .swiper-slide-prev {
    width: 246px !important;
    z-index: 8;
    opacity: 0.56;
    transform: scale(0.8);
  }

  .swiper-slide-next {
    width: 246px !important;
    z-index: 8;
    opacity: 0.56;
    transform: scale(0.8);
  }
}

::v-deep .el-carousel__item {
  box-shadow: 0px 2px 40px 0 #354a32;
  opacity: 0.6;
}

::v-deep .el-carousel__item.is-active {
  box-shadow: 0px 2px 40px 0 #354a32;
  opacity: 1;
}

::v-deep .el-pagination .btn-next,
.el-pagination .btn-prev {
  background-color: transparent;
}
::v-deep .el-pagination button:disabled {
  background-color: transparent;
}
</style>
